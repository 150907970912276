import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Slide,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Notification, useLogin, useNotify } from 'react-admin';
import logo from '../assets/logo.png';

const useStyles = makeStyles(theme => ({
  notifBg: {
    backgroundColor: '#fc5a5d',
  },
  linkForgot: {
    marginTop: theme.spacing(2),
    display: 'flex',
    color: '#0082CD',
    fontSize: '16px',
    fontWeight: 500,
    justifyContent: 'center',
  },
}));

const BoxLogo = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  margin: '0 auto 70px',
}));

const BoxLogin = styled(Box)(({ theme }) => ({
  minHeight: '100vH',
}));

const TitleLogin = styled(Typography)(({ theme }) => ({
  fontSize: '70px',
  lineHeight: '70.4px',
  fontWeight: 700,
  fontFamily: 'Abhaya Libre',
  color: '#34495E',
}));

const SubTitleLogin = styled(Typography)(({ theme }) => ({
  fontSize: '25px',
  lineHeight: '28.8px',
  fontWeight: 300,
  color: 'rgb(187, 187, 187)',
  paddingLeft: '15px',
  marginBottom: '40px',
}));

const UsernameField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  backgroundColor: '#F6F7FA',
  '& input': {
    color: '#9D9FA0',
  },
  '& label': {
    color: '#9D9FA0',
  },
}));

const PasswordField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  backgroundColor: '#F6F7FA',
  '& input': {
    color: '#9D9FA0',
  },
  '& label': {
    color: '#9D9FA0',
  },
}));

const ButtonSubmit = styled(Button)(({ theme }) => ({
  backgroundColor: ' #B22A2E',
  height: '70px',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '24px',
  '&:hover': {
    backgroundColor: ' #aa1e21',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoginPage = ({ theme }) => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const login = useLogin();
  const notify = useNotify();
  const submit = e => {
    e.preventDefault();
    login({ username, password }).catch(() =>
      notify('Invalid NIK or password')
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Container
        maxWidth="sm"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: '#FFFFFF',
        }}>
        <BoxLogin p={3}>
          <BoxLogo>
            <img src={logo} width="50%" />
          </BoxLogo>
          <Box>
            <TitleLogin variant="h2">Welcome !</TitleLogin>
            <SubTitleLogin variant="subtitle1">Login to continue</SubTitleLogin>
            <form onSubmit={submit}>
              <UsernameField
                onChange={e => setUsername(e.target.value)}
                id="username"
                name="username"
                label="Member Id"
                type="text"
                variant="outlined"
              />
              <PasswordField
                onChange={e => setPassword(e.target.value)}
                id="password"
                name="password"
                label="Password No. Hp"
                type="password"
                variant="outlined"
              />
              <ButtonSubmit
                type="submit"
                fullWidth
                variant="contained"
                color="primary">
                Login
              </ButtonSubmit>
              <Link
                className={classes.linkForgot}
                href="#"
                onClick={handleClickOpen}>
                Forgot NIK/Password?
              </Link>
            </form>
            <Notification className={classes.notifBg} align="center" />
          </Box>
        </BoxLogin>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">
            {'Informasi Pergantian Password'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Untuk Pergantian Password Harap menghubungi pihak customer service
              kami. Terimakasih
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </MuiThemeProvider>
  );
};

export default LoginPage;
