import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Skeleton } from '@material-ui/lab';
import useAxios from 'axios-hooks';
import React, { useEffect } from 'react';
import { useGetIdentity } from 'react-admin';
import { useHistory } from 'react-router';
import bgPay from '../../assets/bgPay.png';

const useStyles = makeStyles(theme => ({
  titlePage: {
    textAlign: 'center',
    fontFamily: 'Abhaya Libre',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '33.6px',
  },
  contentWrapper: {
    minHeight: '100vH',
  },
  headerBar: {
    padding: theme.spacing(2, 2),
  },
  arrowBack: {
    color: '#EC5F5F',
    paddingLeft: '5px',
  },
  backButton: {
    backgroundColor: '#F6F7FA',
  },
  listWrapper: {
    padding: theme.spacing(3, 2),
  },
  imgItems: {
    width: '50px',
    height: '50px',
  },
  itemLabel: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
  },
  itemSubLabel: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '1px',
  },
  labelPoint: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    color: '#27AE60',
    paddingLeft: theme.spacing(2),
  },
  labelPointMinus: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    color: '#EB5757',
    paddingLeft: theme.spacing(2),
  },
  linkItems: {
    width: '100%',
    display: 'block',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  },
  tgllabel: {
    color: '#aaa',
    fontSize: '12px',
    fontWeight: 300,
  },
}));

const ButtonBack = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <ButtonBase onClick={() => history.push(`/`)}>
      <Avatar variant="rounded" className={classes.backButton}>
        <ArrowBackIosIcon className={classes.arrowBack} />
      </Avatar>
    </ButtonBase>
  );
};

const ItemsTransaction = items => {
  const classes = useStyles();
  const history = useHistory();
  const dt = items.items;
  const pay = number => {
    let number_string = number.replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      over = split[0].length % 3,
      rp = split[0].substr(0, over),
      thousand = split[0].substr(over).match(/\d{3}/gi);
    if (thousand) {
      let separator = over ? '.' : '';
      rp += separator + thousand.join('.');
    }

    return rp;
  };
  return (
    <ButtonBase
      className={classes.linkItems}
      onClick={() => history.push(`/my_transaction/${dt.id}`)}>
      <Paper elevation={0}>
        <Box display="flex" flexWrap="nowrap" p={1} m={1}>
          <Box p={1} flexShrink={1}>
            <img src={bgPay} className={classes.imgItems} />
          </Box>
          <Box p={1} width="100%">
            <Typography className={classes.itemLabel} variant="h5">
              {dt.type_label.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
            </Typography>
            <Typography className={classes.itemSubLabel} variant="span">
              {dt.tgl_transaksi}
            </Typography>
            <Typography
              className={
                dt.type_id.toLowerCase() == 'tukar_poin'
                  ? classes.labelPointMinus
                  : classes.labelPoint
              }
              variant="span">
              {/* {dt.type_id.toLowerCase() == 'tukar_poin'
                ? dt.point + ' P'
                : '+ ' + dt.point + ' P'} */}
              {dt.type_id.toLowerCase() == 'tukar_poin' ? dt.point + ' P' : ''}
            </Typography>
            {/* <Typography className={classes.tgllabel}>
              {dt.tgl_transaksi}
            </Typography> */}
          </Box>
          <Box p={1} flexShrink={0} align="right">
            <Typography className={classes.itemSubLabel}>IDR</Typography>
            <Typography className={classes.itemSubLabel}>
              {pay(dt.gross_value)}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </ButtonBase>
  );
};

const ListTransaction = () => {
  const classes = useStyles();

  const { loading: loadId, error: errId, identity } = useGetIdentity();

  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions/fn/listmytransaction`
  );

  useEffect(() => {
    if (!loadId && !errId) {
      refetch();
    }
  }, [identity, loadId, errId]);

  return (
    <Box className={classes.listWrapper}>
      {!loading && !error ? (
        data.map((itm, idx) => <ItemsTransaction items={itm} />)
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}
    </Box>
  );
};

const ListMyTransaction = props => {
  const classes = useStyles();
  return (
    <>
      <AppBar color="inherit" position="sticky" elevation={0}>
        <Toolbar className={classes.headerBar}>
          <Grid container>
            <Grid item xs={1}>
              <ButtonBack />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.titlePage} variant="h5">
                Transaction
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box className={classes.contentWrapper}>
        <ListTransaction />
      </Box>
    </>
  );
};

export default ListMyTransaction;
