import { AppBar, Avatar, Box, ButtonBase, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  ImageField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    minHeight: '100vH',
  },
  ImageWrapper: {
    marginLeft: '-24px',
    marginRight: '-18px',
  },
  banner: {
    '& img': {
      width: '100%',
      maxHeight: 'inherit',
    },
  },
  headerBar: {
    padding: theme.spacing(2, 0),
  },
  arrowBack: {
    color: '#EC5F5F',
    paddingLeft: '5px',
  },
  backButton: {
    backgroundColor: '#F6F7FA',
  },
  titleNews: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  content: {
    fontSize: '15px',
    lineHeight: '22.5px',
    fontWeight: 400,
    letterSpacing: '-0.24px',
    textAlign: 'justify',
  },
}));

const ButtonBack = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <ButtonBase onClick={() => history.push(`/`)}>
      <Avatar variant="rounded" className={classes.backButton}>
        <ArrowBackIosIcon className={classes.arrowBack} />
      </Avatar>
    </ButtonBase>
  );
};

const ShowNews = props => {
  const classes = useStyles();
  console.log(props);
  return (
    <Show {...props} actions={false}>
      <SimpleShowLayout>
        <AppBar color="inherit" position="sticky" elevation={0}>
          <Toolbar className={classes.headerBar}>
            <ButtonBack />
          </Toolbar>
        </AppBar>
        <Box className={classes.contentWrapper}>
          <Box className={classes.ImageWrapper}>
            <ImageField source="banner" className={classes.banner} />
          </Box>
          <Box>
            <TextField
              source="title"
              label={false}
              className={classes.titleNews}
            />
            <RichTextField
              source="context"
              label={false}
              className={classes.content}
            />
          </Box>
        </Box>
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowNews;
