import { CssBaseline } from '@material-ui/core';
import axios from 'axios';
import { createBrowserHistory as createHistory } from 'history';
import Dashboard from 'layout/dashboard';
import MainLayout from 'layout/layout';
import LoginPage from 'layout/loginpage';
import LogoutBtn from 'layout/logoutbtn';
import theme from 'layout/theme';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import indonesianMessages from 'ra-language-indonesian-new';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import SetAuthProvider, { ls } from './providers/auth';
import SetDataProvider from './providers/data';
import ListMyTransaction from './resources/my_transaction/list';
import ShowTransaction from './resources/my_transaction/show';
import CreateNews from './resources/news/create';
import ListNews from './resources/news/list';
import ShowNews from './resources/news/show';

require('@openfonts/poppins_latin');
require('@openfonts/abhaya-libre_latin');

const history = createHistory({ basename: process.env.REACT_APP_BASE_PATH });

const messages = {
  id: indonesianMessages,
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'id');

axios.interceptors.request.use(config => {
  const session = ls.get('session');

  if (session.token) {
    config.headers.authorization = `Bearer ${session.token}`;
  }

  return config;
});

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    console.error('AXIOS', error.response);
    return Promise.reject({
      message: error.response.data?.error || error.message,
      status: error.status,
      axios: error,
    });
  }
);

const dataProvider = SetDataProvider(process.env.REACT_APP_API_URL);
const authProvider = SetAuthProvider(process.env.REACT_APP_API_URL);

const App = () => {
  return (
    <>
      <CssBaseline />
      <Admin
        theme={theme}
        logoutButton={LogoutBtn}
        loginPage={LoginPage}
        layout={MainLayout}
        dashboard={Dashboard}
        history={history}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}>
        <Resource
          name="news"
          list={ListNews}
          create={CreateNews}
          edit={ShowNews}
        />
        <Resource
          name="my_transaction"
          list={ListMyTransaction}
          edit={ShowTransaction}
        />
      </Admin>
    </>
  );
};

export default App;
