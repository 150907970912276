import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Grid,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Skeleton } from '@material-ui/lab';
import useAxios from 'axios-hooks';
import { useHistory } from 'react-router';
import bgTransaction from '../../assets/bgTrasaction.png';
import noImage from '../../assets/no-image.jpg';

const useStyles = makeStyles(theme => ({
  titlePage: {
    textAlign: 'center',
    fontFamily: 'Abhaya Libre',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '33.6px',
  },
  contentWrapper: {
    minHeight: '100vH',
    backgroundColor: '#fff',
  },
  detailWrapper: {
    padding: '24px 5%',
  },
  detailItems: {
    padding: '24px 5%',
    borderTop: '1px dashed #ddd',
  },
  headerBar: {
    padding: theme.spacing(2, 2),
  },
  arrowBack: {
    color: '#EC5F5F',
    paddingLeft: '5px',
  },
  backButton: {
    backgroundColor: '#F6F7FA',
  },
  detailTitlePage: {
    textAlign: 'center',
    fontFamily: 'Abhaya Libre',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '20x',
  },
  detailPay: {
    textAlign: 'center',
    fontFamily: 'Abhaya Libre',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '20x',
    letterSpacing: '-0.24px',
  },
  detailContent: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20x',
    letterSpacing: '-0.24px',
    color: '#7F8C8D',
    marginTop: theme.spacing(3),
  },
  boldText: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#34495E',
  },
}));

const NumberFormat = ({ number, curency }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + rp : rp;
};

const ButtonBack = ({ basePath }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ButtonBase onClick={() => history.push(basePath)}>
      <Avatar variant="rounded" className={classes.backButton}>
        <ArrowBackIosIcon className={classes.arrowBack} />
      </Avatar>
    </ButtonBase>
  );
};

const DetailTransaction = ({ id }) => {
  const classes = useStyles();

  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions/fn/detailtransaction/${id}`
  );

  const pay = number => {
    let number_string = number.replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      over = split[0].length % 3,
      rp = split[0].substr(0, over),
      thousand = split[0].substr(over).match(/\d{3}/gi);
    if (thousand) {
      let separator = over ? '.' : '';
      rp += separator + thousand.join('.');
    }

    return rp;
  };

  return (
    <>
      <Box align="center">
        <img src={bgTransaction} width="30%" />
      </Box>
      <Box className={classes.detailWrapper}>
        <Typography variant="h3" className={classes.detailTitlePage}>
          {!loading && !error ? (
            data.type_id.toLowerCase() == 'tukar_poin' ? (
              'Point Saat Ini'
            ) : (
              'Total Pembayaran'
            )
          ) : (
            <Skeleton />
          )}
        </Typography>
        <Typography variant="h3" className={classes.detailPay}>
          {!loading && !error ? (
            data.type_id.toLowerCase() == 'tukar_poin' ? (
              data.current_point
            ) : (
              'Rp ' + pay(data.gross_value)
            )
          ) : (
            <Skeleton />
          )}
        </Typography>
        <Grid container className={classes.detailContent}>
          <Grid item xs={6}>
            <Box p={2}>
              <Typography>Jenis Transaksi</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} align="right">
            <Box p={2}>
              <Typography className={classes.boldText}>
                {!loading && !error ? (
                  data.type_id.toLowerCase() == 'tukar_poin' ? (
                    'Penukaran Point'
                  ) : (
                    'Pembelian Barang'
                  )
                ) : (
                  <Skeleton />
                )}
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={6}>
            <Box p={2}>
              <Typography>Point</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} align="right">
            <Box p={2}>
              <Typography className={classes.boldText}>
                {!loading && !error ? (
                  data.type_id.toLowerCase() == 'tukar_poin' ? (
                    data.point + ' P'
                  ) : (
                    '+ ' + data.point + ' P'
                  )
                ) : (
                  <Skeleton />
                )}
              </Typography>
            </Box>
          </Grid> */}
          <Grid item xs={6}>
            <Box p={2}>
              <Typography>Tanggal</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} align="right">
            <Box p={2}>
              <Typography className={classes.boldText}>
                {!loading && !error ? data.tgl_trasaction : <Skeleton />}
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={6} p={3}>
            <Box p={2}>
              <Typography>Penerima</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} align="right">
            <Box p={2}>
              <Typography className={classes.boldText}>
                Kezia Skincare
              </Typography>
            </Box>
          </Grid> */}
        </Grid>
      </Box>

      {!loading && !error ? (
        data.type_id.toLowerCase() !== 'tukar_poin' ? (
          <Box className={classes.detailItems}>
            <Typography className={classes.titleDetailItems}>
              Detail Pembelian Barang
            </Typography>
            {data.details.map((items, index) => (
              <Box display="flex" alignItems="center">
                <Box p={1} flexShrink={3}>
                  <img src={items.photo ? items.photo : noImage} width="100%" />
                </Box>
                <Box p={1} width="100%">
                  <Typography className={classes.titleProduct}>
                    {items.product_name}
                  </Typography>
                  <Box display="flex">
                    <Box flexShrink={3}>
                      <Typography
                        className={
                          classes.titleProduct
                        }>{`X ${items.qty}`}</Typography>
                    </Box>
                    <Box width="80%" align="right">
                      <Typography className={classes.textItems}>
                        <NumberFormat
                          number={parseInt(items.price_per_qty)}
                          curency="Rp "
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : null
      ) : null}
    </>
  );
};

const ShowTransaction = props => {
  const classes = useStyles();

  return (
    <>
      <AppBar color="inherit" position="sticky" elevation={0}>
        <Toolbar className={classes.headerBar}>
          <Grid container>
            <Grid item xs={1}>
              <ButtonBack basePath={props.basePath} />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.titlePage} variant="h5">
                Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box className={classes.contentWrapper}>
        <DetailTransaction id={props.id} />
      </Box>
    </>
  );
};

export default ShowTransaction;
