import axios from 'axios';
import SecureLS from 'secure-ls';

export const ls = new SecureLS({ encodingType: 'aes', isCompression: true });

const httpClient = (url, config) =>
  axios.request(typeof url === 'object' ? url : { url, ...config });

const SetAuthProvider = apiUrl => ({
  // authentication
  login: async ({ username, password }) => {
    try {
      const { data } = await httpClient(`${apiUrl}/auth/fn/login`, {
        method: 'POST',
        data: { username, password, usertype: 'customer' },
      });

      console.log(data);

      ls.set('session', data);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  checkError: error => {
    if (error.status === 401 || error.status === 403) {
      ls.remove('session');
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    const session = ls.get('session');

    if (session) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  logout: async () => {
    try {
      await httpClient(`${apiUrl}/auth/fn/logout`, {
        method: 'POST',
      });

      ls.remove('session');

      return Promise.resolve();
    } catch (error) {
      //  return Promise.reject(error);
      console.error(error);
      ls.remove('session');
      return Promise.resolve();
    }
  },
  getIdentity: async () => {
    try {
      const { data } = await httpClient(`${apiUrl}/auth/fn/session`, {
        method: 'GET',
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: params => {
    /* ... */
    return Promise.resolve({});
  },
});

export default SetAuthProvider;
