import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import { Skeleton } from '@material-ui/lab';
import useAxios from 'axios-hooks';
import React, { useEffect } from 'react';
import {
  ListBase,
  useGetIdentity,
  useListContext,
  useLogout,
} from 'react-admin';
import Carousel from 'react-material-ui-carousel';
import { useHistory } from 'react-router';
import bgPoint from '../assets/bgPoint.png';
import logo from '../assets/logo.png';

const KeziaBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'url(' + bgPoint + ')',
  padding: theme.spacing(3),
  backgroundColor: '#B22A2E',
  backgroundPosition: 'left 5px',
  backgroundSize: 'auto 100%',
  backgroundRepeat: 'no-repeat',
}));

const useStyles = makeStyles(theme => ({
  logoSmall: {
    width: '100px',
  },
  bannerItems: {
    width: '100%',
    border: 'none',
    cursor: 'pointer',
  },
  imgBanner: {
    width: '100%',
    border: 'none',
  },
  btnDanger: {
    color: '#F44336',
  },
  barCustom: {
    borderBottom: '10px solid #B22A2E',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  topContent: {
    padding: theme.spacing(3, 2),
  },
  titlePage: {
    fontFamily: 'Abhaya Libre',
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '30px',
    letterSpacing: '-0.24px',
    marginBottom: 0,
  },
  subTitlePage: {
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    color: '#7F8C8D',
  },
  pointTitle: {
    color: '#ffffff',
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: '45px',
    letterSpacing: '-0.24px',
  },
  pointHelper: {
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '3px',
  },
  smallTitle: {
    fontFamily: 'Abhaya Libre',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '3px',
  },
  contentCenter: {
    alignItems: 'center',
  },
  boxProfile: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    margin: theme.spacing(4, 1, 1),
  },
  listProfile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  profileTitle: {
    fontFamily: 'Abhaya Libre',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '3px',
  },
  profileLabel: {
    color: '#7F8C8D',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '20px',
  },
  profileText: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

const NewsItems = () => {
  const classes = useStyles();

  const { data, ids, total, loading, loaded } = useListContext();
  const history = useHistory();
  return (
    <Carousel>
      {!loading &&
        loaded &&
        ids.map((id, idx) => {
          const dt = data[id];
          return (
            <ButtonBase
              class={classes.bannerItems}
              key={idx}
              onClick={() => history.push(`/news/${id}`)}>
              <img src={dt.banner} class={classes.imgBanner} />
            </ButtonBase>
          );
        })}
    </Carousel>
  );
};

const LatestNews = () => (
  <ListBase
    basePath="/"
    resource="news"
    sort={{ field: 'id', order: 'DESC' }}
    filter={{ active: '1' }}>
    <NewsItems />
  </ListBase>
);

const MyPoints = theme => {
  const classes = useStyles();
  const history = useHistory();
  const { loading: loadId, error: errId, identity } = useGetIdentity();
  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions/fn/mypoint`
  );

  useEffect(() => {
    if (!loadId && !errId) {
      refetch();
    }
  }, [identity, loadId, errId, refetch]);

  const point = myPoint => {
    return (myPoint < 100 ? '0' : '') + (myPoint < 10 ? '0' : '') + myPoint;
  };

  return (
    <KeziaBox m={1}>
      <ButtonBase onClick={() => history.push(`/my_transaction`)}>
        <Grid container className={classes.contentCenter} spacing={1}>
          <Grid item xs={3}>
            <Typography align="center" className={classes.smallTitle}>
              MY POINTS
            </Typography>
          </Grid>
          <Grid item xs={9} align="right">
            <IconButton>
              <ArrowForwardIosIcon style={{ color: '#fff' }} />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <Typography align="center" className={classes.pointTitle}>
              {!loading && !error ? point(data.my_point) : <Skeleton />}
            </Typography>
            <Typography align="center" className={classes.pointHelper}>
              POINTS
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              align="center"
              style={{
                fontSize: '20px',
                color: '#fff',
                lineHeight: '28px',
                fontWeight: 300,
              }}>
              Your additional points
              <br />
              will always be updated
            </Typography>
          </Grid>
        </Grid>
      </ButtonBase>
    </KeziaBox>
  );
};

const Dashboard = ({ props }) => {
  const classes = useStyles();

  const { loading, error, identity } = useGetIdentity();
  const logout = useLogout();
  const handleClick = () => logout();

  const generateCode = name => {
    const words = name.trim().split(' ');

    const first = words[0].substring(0, 1).toUpperCase();
    const last = words[words.length - 1].substring(0, 1).toUpperCase();

    return `${first}${last}`;
  };

  return (
    <>
      <AppBar
        className={classes.barCustom}
        color="inherit"
        position="sticky"
        elevation={0}>
        <Toolbar>
          <img src={logo} className={classes.logoSmall} />
          <Box flexGrow={1}></Box>
          <IconButton onClick={handleClick} className={classes.btnDanger}>
            <ExitIcon />
          </IconButton>
          <Avatar color="#CCCCCC">
            {identity && !loading && !error && identity.customer_name
              ? generateCode(identity.customer_name)
              : null}
          </Avatar>
        </Toolbar>
      </AppBar>
      <Box className={classes.topContent} bgcolor="background.paper">
        <Typography
          className={classes.titlePage}
          variant="h3"
          component="h1"
          gutterBottom>
          {identity && !loading && !error ? (
            `Hai, ${identity.customer_name}`
          ) : (
            <Skeleton />
          )}
        </Typography>
        <Typography className={classes.subTitlePage}>
          Selamat datang di Kezia skin care
        </Typography>
      </Box>
      <Box px={2} py={1}>
        <Box>
          <LatestNews />
          <MyPoints />
          <Box className={classes.boxProfile}>
            <Typography className={classes.profileTitle}>My Profile</Typography>
            <List>
              <ListItem className={classes.listProfile}>
                <ListItemText
                  primary={
                    <Typography className={classes.profileLabel}>
                      Nama
                    </Typography>
                  }
                  secondary={
                    identity && !loading ? (
                      <Typography className={classes.profileText}>
                        {identity.customer_name}
                      </Typography>
                    ) : (
                      <Skeleton />
                    )
                  }
                />
              </ListItem>
              <ListItem className={classes.listProfile}>
                <ListItemText
                  primary={
                    <Typography className={classes.profileLabel}>
                      Area
                    </Typography>
                  }
                  secondary={
                    identity && !loading ? (
                      <Typography className={classes.profileText}>
                        {identity.area}
                      </Typography>
                    ) : (
                      <Skeleton />
                    )
                  }
                />
              </ListItem>
              <ListItem className={classes.listProfile}>
                <ListItemText
                  primary={
                    <Typography className={classes.profileLabel}>
                      NIK
                    </Typography>
                  }
                  secondary={
                    identity && !loading ? (
                      <Typography className={classes.profileText}>
                        {identity.nik}
                      </Typography>
                    ) : (
                      <Skeleton />
                    )
                  }
                />
              </ListItem>
              <ListItem className={classes.listProfile}>
                <ListItemText
                  primary={
                    <Typography className={classes.profileLabel}>
                      Store
                    </Typography>
                  }
                  secondary={
                    identity && !loading ? (
                      <Typography className={classes.profileText}>
                        {identity.shops}
                      </Typography>
                    ) : (
                      <Skeleton />
                    )
                  }
                />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
