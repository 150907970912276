import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const CreateNews = props => (
  <Create {...props}>
    <SimpleForm>
      <ImageInput source="banner" label="Banner" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="title" />
      <RichTextInput source="context" label="Description" />
    </SimpleForm>
  </Create>
);

export default CreateNews;
