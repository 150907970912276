import {
  Datagrid,
  List,
  TextField,
  ImageField,
  RichTextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: 50,
      width: 50,
      objectFit: 'contain',
    },
  },
});

const ListNews = props => {
  const classes = useStyles();
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ImageField className={classes.imgContainer} source="banner" />
        <TextField source="title" />
        <RichTextField source="context" />
      </Datagrid>
    </List>
  );
};

export default ListNews;
