import { Container, ThemeProvider } from '@material-ui/core';
import theme from './theme';

const MainLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="sm"
        style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#F1F4FA' }}>
        {children}
      </Container>
    </ThemeProvider>
  );
};

export default MainLayout;
